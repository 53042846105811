import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import QuestionnaireQuestion from "./QuestionnaireQuestion";
import axios from "axios";
import Finish from "../assets/icons/finish.svg";
import QuestionnaireModal from "./QuestionnaireModal";

import Active from "../assets/icons/active.svg";
import Done from "../assets/icons/done.svg";
import Empty from "../assets/icons/empty.svg";
import Spinner from "../assets/spinner.svg";
import Skipped from "../assets/icons/skipped.svg";

type actionCluster = "next" | "prev" | "skip";
type actionStatus = "empty" | "active" | "done" | "skipped";
interface IBreadcrumb {
  index: number;
  name: string;
  status: actionStatus;
}

const Questionnaire = ({
  setSuccess,
  setIsQuestionnaireFinished,
  setFormValues,
  setClusterList,
}: any) => {
  const { register, handleSubmit, getValues, setFocus, setValue } = useForm();
  const [questionsList, setQuestionsList] = useState<Array<any>>([[]]);
  const [clusterNumber, setClusterNumber] = useState<number>(0);
  const [popUp, setPopUp] = useState<any>({
    active: false,
    submitButton: "Submit",
    closeButton: "Cancel",
    description: "Default Description",
    callbackFn: () => {},
  });
  const [breadcrumb, setBreadcrumb] = useState<IBreadcrumb[] | undefined>(
    undefined
  );
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [filledCluster, setFieldCluster] = useState<number>(0);

  const groupByCluster = (data: any) => {
    const groupedArrays: any = {};
    for (const element of data) {
      if (!groupedArrays[element.cluster]) {
        groupedArrays[element.cluster] = [];
      }
      groupedArrays[element.cluster].push(element);
    }
    return Object.values(groupedArrays);
  };

  const onFinish = (values: any) => {
    let currentRespondedValues = values;

    let foundElements = [];
    let emptyInputs: any = [];
    let errorHandling = false;

    questionsList[clusterNumber].forEach((element: any) => {
      let questionValue = values[element.id];
      if (questionValue === null) {
        emptyInputs.push(element.id);
      } else {
        errorHandling = true;
      }
    });

    if (errorHandling && emptyInputs.length > 0) {
      setSuccess({
        image: "Danger",
        backgroundClass: "bg-red-danger",
        message: `Please answer all the questions of this cluster before finishing it.`,
      });
    } else {
      for (let cluster of questionsList) {
        cluster.forEach((element: any) => {
          let questionValue = currentRespondedValues[element.id];
          if (questionValue) {
            foundElements.push(questionValue);
          }
        });
      }
      if (foundElements.length >= 8) {
        setFormValues(currentRespondedValues);
        setIsQuestionnaireFinished(true);
        setSuccess(null);
      } else {
        setPopUp({
          active: true,
          closeButton: "Go back to questionnaire",
          description:
            "You have to complete at least one cluster before being able to end the questionnaire",
        });
      }
    }
  };

  const clearCusterAnswersAndFinish = () => {
    clearCusterAnswers(questionsList?.length - 1, "skip");
    const values = getValues();
    onFinish(values);
  };

  const moveClusterNumber = (status: actionCluster) => {
    setClusterNumber(
      (prevClusterNumber) => prevClusterNumber + (status === "prev" ? -1 : 1)
    );
  };

  const clearCusterAnswers = (clusterNumber: number, status: actionCluster) => {
    const valuesToClear = questionsList[clusterNumber].map(
      (element: any) => element?.id
    );
    valuesToClear.forEach((element: string) => {
      setValue(element, null);
    });
    (clusterNumber < questionsList?.length - 1 || status === "prev") &&
      moveClusterNumber(status);
  };

  function findEmptyRecords() {
    let emptyInputs: Array<any> = [];
    let errorHandling = false;
    let currentValues = getValues();
    questionsList[clusterNumber].forEach((element: any) => {
      if (currentValues[element.id] === null) {
        emptyInputs.push(element.id);
      } else {
        errorHandling = true;
      }
    });

    return { emptyInputs: emptyInputs, errorHandling: errorHandling };
  }

  const onNextOrPrevCluster = (status: actionCluster) => {
    let emptyRecords = findEmptyRecords();
    let emptyInputs: Array<any> = emptyRecords.emptyInputs;
    let errorHandling = emptyRecords.errorHandling;

    status === "skip" &&
      clusterNumber === questionsList?.length - 1 &&
      (errorHandling = true);
    if (errorHandling && emptyInputs.length > 0) {
      status === "next" &&
        setSuccess({
          image: "Danger",
          backgroundClass: "bg-red-danger",
          message: `Please answer all the questions of this cluster before going to the next one.`,
        });
      status === "prev" &&
        setPopUp({
          active: true,
          submitButton: "Confirm",
          closeButton: "No, I want to answer all the questions",
          description:
            "Please note that the provided answers for this cluster will be lost if you go back to the previous cluster",
          callbackFn: () => {
            clearCusterAnswers(clusterNumber, "prev");
            setPopUp({ ...popUp, active: false });
            setFocus(questionsList[clusterNumber][0].id);
          },
        });
      status === "skip" &&
        clusterNumber < questionsList?.length - 1 &&
        setPopUp({
          active: true,
          submitButton: "Confirm",
          closeButton: "No, I want to answer all the questions",
          description:
            "Please note that the provided answers for this cluster will be lost if you skip it",
          callbackFn: () => {
            clearCusterAnswers(clusterNumber, "skip");
            setPopUp({ ...popUp, active: false });
          },
        });
      if (
        status === "skip" &&
        clusterNumber === questionsList?.length - 1 &&
        emptyInputs.length === questionsList[clusterNumber].length
      ) {
        clearCusterAnswersAndFinish();
      } else if (
        status === "skip" &&
        clusterNumber === questionsList?.length - 1 &&
        emptyInputs.length < questionsList[clusterNumber].length &&
        filledCluster === 0
      ) {
        setPopUp({
          active: true,
          closeButton: "Go back to questionnaire",
          description:
            "You have to complete at least one cluster before being able to end the questionnaire",
        });
      } else if (
        status === "skip" &&
        clusterNumber === questionsList?.length - 1 &&
        emptyInputs.length < questionsList[clusterNumber].length
      ) {
        setPopUp({
          active: true,
          submitButton: "See results",
          closeButton: "No, I want to answer all the questions",
          description:
            "Please note that the provided answers for this cluster will be lost if you skip it",
          callbackFn: handleSubmit(clearCusterAnswersAndFinish),
        });
      }
    } else if (status === "skip" && emptyInputs.length === 0) {
      clusterNumber < questionsList?.length - 1
        ? setPopUp({
            active: true,
            submitButton: "Confirm",
            closeButton: "No, I want to answer all the questions",
            description:
              "Please note that the provided answers will be lost if you skip the cluster",
            callbackFn: () => {
              clearCusterAnswers(clusterNumber, "skip");
              setPopUp({ ...popUp, active: false });
            },
          })
        : setPopUp({
            active: true,
            submitButton: "Confirm and submit",
            closeButton: "No, I want to answer all the questions",
            description:
              "Please note that the provided answers will be lost if you skip the cluster",
            callbackFn: handleSubmit(clearCusterAnswersAndFinish),
          });
    } else {
      setSuccess(null);
      setFocus(questionsList[clusterNumber][0].id);
      setClusterNumber(
        (prevClusterNumber) => prevClusterNumber + (status === "prev" ? -1 : 1)
      );
      emptyInputs.length === 0 && setFieldCluster((prevState) => prevState + 1);
    }
  };

  const createClusterList = (questions: any) => {
    const clusterListState: string[] = [];
    questions.forEach((element: any) => {
      !clusterListState.includes(element?.cluster) &&
        clusterListState.push(element?.cluster);
    });
    setClusterList(clusterListState);
  };

  useEffect(() => {
    document.getElementById("questionnaire")?.scrollIntoView();
    setIsLoader(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}questions`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        createClusterList(res?.data?.questions);
        setQuestionsList(groupByCluster(res?.data?.questions));
        setIsLoader(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (questionsList.length > 1) {
      let breadcrumbValues: IBreadcrumb[] = [];
      for (let i = 0; i < questionsList.length; i++) {
        breadcrumbValues.push({
          index: i,
          name: questionsList[i][0].cluster,
          status: i === 0 ? "active" : "empty",
        });
      }
      setBreadcrumb(breadcrumbValues);
    }
  }, [questionsList]);

  useEffect(() => {
    const newBreadcrumb = breadcrumb?.map((obj) => {
      if (obj.index === clusterNumber) {
        obj.status = "active";
      } else {
        let values = getValues();

        let getNumberOfAnswears = 0;
        questionsList[obj.index].forEach((element: any) => {
          if (values[element.id]) {
            getNumberOfAnswears++;
          }
        });
        if (getNumberOfAnswears === questionsList[obj.index].length) {
          obj.status = "done";
        } else if (obj.index < clusterNumber) {
          obj.status = "skipped";
        } else {
          obj.status = "empty";
        }
      }

      return obj;
    });

    setBreadcrumb(newBreadcrumb);
    // eslint-disable-next-line
  }, [clusterNumber]);

  return (
    <div id="questionnaire" className="px-[10vw]">
      <div className="w-1/2 mb-28">
        <h1 className="text-2xl font-bold mb-4">City Scan questionnaire</h1>
        <p className="text-base">
          The test is about 48 questions ("Yes/No" or "High/Low") divided into 6
          categories which represent different clusters. Answering all the
          questions isn't mandatory. In order to see your first results and get
          your free PDF report, you'll have to complete at least one cluster; a
          cluster is considered as completed once you have answered the 8
          questions for this cluster
        </p>
      </div>
      <form className="flex flex-col items-center">
        {isLoader ? (
          <img
            src={Spinner}
            alt="spinner"
            className="animate-spin w-14 mx-auto mb-4"
          />
        ) : (
          <>
            <section className="relative w-full mb-3 h-20">
              <div className="w-full flex justify-between absolute -top-[15px]">
                {breadcrumb &&
                  breadcrumb.map(({ index, name, status }: any) => {
                    let src = Empty;
                    switch (status) {
                      case "active":
                        src = Active;
                        break;
                      case "done":
                        src = Done;
                        break;
                      case "skipped":
                        src = Skipped;
                        break;
                    }

                    return (
                      <div className="relative" key={index}>
                        <img src={src} alt="" width={33} height={33} />
                        {status === "active" && (
                          <span className="relative -top-[28px] left-[12px] text-blue-400">
                            {index + 1}
                          </span>
                        )}
                        {status === "empty" && (
                          <span className="relative -top-[28px] left-[12px]">
                            {index + 1}
                          </span>
                        )}
                        <span className="absolute -left-[40px] w-[113px] text-[16px] text-center">
                          {name.charAt(0).toUpperCase() + name.slice(1)} city
                        </span>
                      </div>
                    );
                  })}
              </div>

              <div className=" bg-gray-200 rounded-full h-0.5 dark:bg-gray-700 mr-1 ml-1">
                <div
                  className="bg-blue-400 h-0.5 rounded-full"
                  style={{
                    width:
                      (clusterNumber / (questionsList?.length - 1)) * 100 + "%",
                  }}
                />
              </div>
            </section>

            <button
              type="button"
              className="bg-white rounded-md border-2 border-blue-200 w-36 h-14 self-end text-blue-500 mr-2"
              onClick={() => onNextOrPrevCluster("skip")}
            >
              Skip Cluster
            </button>
            <div className="flex flex-col items-start mt-14">
              {questionsList &&
                questionsList[clusterNumber].map(
                  (question: any, index: number) => (
                    <QuestionnaireQuestion
                      index={index}
                      key={question.id}
                      name={question.id}
                      register={register}
                      question={question.question}
                      possibleAnswers={question.possibleAnswers}
                    />
                  )
                )}
              <div className="flex self-end">
                {clusterNumber > 0 && (
                  <button
                    type="button"
                    onClick={() => onNextOrPrevCluster("prev")}
                    className="bg-white rounded-md border-2 border-blue-200 w-20 h-14 self-end text-blue-500 mr-2"
                  >
                    Back
                  </button>
                )}
                {clusterNumber < questionsList?.length - 1 ? (
                  <button
                    type="button"
                    onClick={() => onNextOrPrevCluster("next")}
                    className="bg-blue-500 rounded-md w-20 h-14 self-end text-white"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="bg-blue-500 rounded-md w-20 h-14 self-end text-white"
                    onClick={handleSubmit(onFinish)}
                  >
                    See the results
                  </button>
                )}
              </div>
              {clusterNumber < questionsList?.length - 1 && (
                <div className="flex self-end mt-2">
                  <button
                    type="button"
                    className="flex items-center hover:text-blue-500"
                    onClick={handleSubmit(onFinish)}
                  >
                    <img src={Finish} alt="" className="w-4 h-auto mr-2" />
                    <span>Finish the test and see the results</span>
                  </button>
                </div>
              )}
              {popUp.active && (
                <QuestionnaireModal popUp={popUp} setPopUp={setPopUp} />
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default Questionnaire;
