import React from "react";
import Success from "../assets/icons/success.svg";
import Danger from "../assets/icons/danger.svg";
import X from "../assets/icons/close-white.svg";

const Snackbar = ({ success, setSuccess }: any) => {
  let iconSrc: string = "";

  switch (success.image) {
    case "Success":
      iconSrc = Success;
      break;
    case "Danger":
      iconSrc = Danger;
      break;
  }

  return (
    <div
      data-testid="snackbar"
      role="alert"
      className={`py-2 px-2 sm:px-4 text-sm box-border flex justify-between ${success.backgroundClass} fixed top-0 right-0 m-1 z-50`}
    >
      <div
        data-testid="snackbar-message"
        className="flex text-white items-center pr-2 sm:pr-0"
      >
        <img
          data-testid="snackbar-image"
          src={iconSrc}
          alt=""
          className="mr-2 sm:mr-4 w-6"
        />
        {success.message}
      </div>
      <button
        data-testid="snackbar-close"
        type="button"
        aria-label="close snackbar"
        onClick={() => setSuccess(null)}
      >
        <img src={X} alt="" width={24} height={24} className="sm:ml-8" />
      </button>
    </div>
  );
};

export default Snackbar;
