import React, { useState, useEffect } from "react";
import axios from "axios";
import ResultsSection from "./ResultsSection";
import PDFForm from "./PDFForm";

const Finish = ({ formValues, setSuccess, cityName, clusterList }: any) => {
  const [surveyId, setSurveyId] = useState<string>("");
  const [scoreList, setScoreList] = useState();
  const [isPDFFormOpen, setIsPDFFormOpen] = useState<boolean>(false);

  useEffect(() => {
    if (formValues) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}survey`,
          {
            answers: formValues,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((res: any) => {
          setSurveyId(res.data.surveyId);
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_URL}survey/${res.data.surveyId}/scoring`,
              { headers: { "Content-Type": "application/json" } }
            )
            .then((res: any) => {
              setScoreList(res.data);
            })
            .catch(() => {
              setSuccess({
                image: "Danger",
                backgroundClass: "bg-red-danger",
                message: `The form scoring could not be fetched.`,
              });
            });
        })
        .catch(() => {
          setSuccess({
            image: "Danger",
            backgroundClass: "bg-red-danger",
            message: `The form cannot be submitted. Please check all fields or try again later.`,
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <section className="grid grid-cols-2 gap-8 px-40 my-16">
      {isPDFFormOpen && (
        <PDFForm
          setSuccess={setSuccess}
          isPDFFormOpen={isPDFFormOpen}
          setIsPDFFormOpen={setIsPDFFormOpen}
          surveyId={surveyId}
          cityName={cityName}
        />
      )}
      <div>
        <h1 className="text-2xl mb-4">City Scan Results</h1>
        <p className="mb-16 h-12">
          Based on your answers about your city here is the basic scoring
          divided into clusters.
        </p>
        <ResultsSection scoreList={scoreList} clusterList={clusterList} />
      </div>
      <div>
        <h1 className="text-2xl mb-4">Do you want to know more?</h1>
        <p className="mb-16">
          Get more information from our free PDF report containing the radar of
          your city, more details with our recommendations regarding each
          cluster.
        </p>
        <div className="flex gap-8">
          <button
            className="border border-sky-500 text-sky-500 px-6 py-3 rounded-md font-bold"
            onClick={() => window.location.reload()}
          >
            Take the test again
          </button>
          <button
            className="border border-sky-500 text-white rounded-md bg-sky-500 px-6 py-3 font-bold"
            onClick={() => setIsPDFFormOpen(true)}
          >
            Register and get the report
          </button>
        </div>
      </div>
    </section>
  );
};

export default Finish;
