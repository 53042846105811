import React from "react";
import {
  FieldValues,
  Message,
  UseFormRegister,
  ValidationRule,
} from "react-hook-form";

const QuestionnaireQuestion = ({
  index,
  name,
  register,
  question,
  possibleAnswers,
}: {
  index: number;
  name: string;
  register: UseFormRegister<FieldValues>;
  required?: Message | ValidationRule<boolean>;
  question: string;
  possibleAnswers: Array<string>;
}) => {
  return (
    <div className="flex mb-12">
      <span className="text-7xl mr-4 text-question-number">{index + 1}</span>
      <div className="flex flex-col items-start max-w-xl">
        <span className="text-xl font-bold mb-12">{question}</span>
        <div className="flex items-center">
          {possibleAnswers &&
            possibleAnswers.map((possibleAnswer: string) => (
              <label
                key={`${name}_${possibleAnswer}`}
                htmlFor={`${name}_${possibleAnswer}`}
                className="mx-2 text-sm flex items-center font-medium text-gray-900 dark:text-gray-300"
              >
                {possibleAnswer}
                <input
                  className="ml-2 w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-0 dark:bg-gray-700 dark:border-gray-600"
                  id={`${name}_${possibleAnswer}`}
                  type="radio"
                  value={possibleAnswer}
                  {...register(name)}
                />
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireQuestion;
