import React, { useState } from "react";
import SearchCity from "./SearchCity";

const Home = ({ cityName, setCityName, setIsQuestionnaireOpen }: any) => {
  const [addressHelperError, setAddressHelperError] = useState(false);

  const showQuestionnaire = () => {
    cityName ? setIsQuestionnaireOpen(true) : setAddressHelperError(true);
  };

  return (
    <div
      className="h-full grid grid-cols-2 items-center justify-items-center bg-center bg-no-repeat bg-cover shadow-[inset_0_0_20px_30px_#fff]"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/hero_big.jpg"})`,
        height: `731px`,
      }}
    >
      <div className="flex flex-col ml-40 max-w-xl">
        <p
          className="text-5xl leading-[3.5rem] mb-4 font-light"
          data-testid="homeText"
        >
          Reveal your city's path to{" "}
          <span className="text-blue-500 font-black italic tracking-tight gradient-color">
            sustainability{" "}
          </span>{" "}
          and shape a{" "}
          <span className="text-blue-500 font-black italic tracking-tight gradient-color">
            {" "}
            brighter future{" "}
          </span>
        </p>
        <form
          className="flex gap-8"
          onSubmit={(event) => event.preventDefault()}
        >
          <SearchCity
            setCityName={setCityName}
            addressHelperError={addressHelperError}
            setAddressHelperError={setAddressHelperError}
          />
          <button
            type="button"
            onClick={showQuestionnaire}
            className="bg-blue-500 w-36 h-[60px] self-end rounded-md text-white"
          >
            Take a short test
          </button>
        </form>
      </div>
      <div className="flex justify-end w-full">{/* Blocker Element */}</div>
    </div>
  );
};

export default Home;
