import React, { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

interface FormInputProps {
  name: string;
  placeholder: string | ReactNode;
  type?: string;
  required?: boolean;
  register: UseFormRegister<any>;
}

const FormInput = ({
  name,
  placeholder,
  type,
  required,
  register,
}: FormInputProps) => (
  <label
    className={`flex items-start text-gray-700 w-full ${
      type !== "checkbox" ? "flex-col" : "items-center"
    }`}
  >
    {type === "checkbox" && (
      <input
        data-testid={`${name}-checkbox`}
        type="checkbox"
        required={required}
        className="w-3 h-3 mr-3"
        {...register(name)}
      />
    )}
    <span className="text-sm text-gray-900">{placeholder}</span>
    {type !== "checkbox" && (
      <input
        data-testid={`${name}-input`}
        type={type}
        required={required}
        className="border-[1px] border-gray-600 px-4 py-2 mt-2 rounded-md w-full"
        {...register(name, { required: required })}
      />
    )}
  </label>
);

export default FormInput;
