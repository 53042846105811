import ChampionScore from "../assets/icons/champion-score.png";
import RightTrackScore from "../assets/icons/right-track-score.png";
import StartingPointScore from "../assets/icons/starting-point-score.png";
// deprecated scores
import GoodScore from "../assets/icons/good-score.svg";
import MiddleScore from "../assets/icons/middle-score.svg";
import BadScore from "../assets/icons/bad-score.svg";

import Spinner from "../assets/spinner.svg";

const ResultsSection = ({ scoreList, clusterList }: any) => {
  const parseScore = (score: any) => {
    let icon = "";
    let text = `${score}`;

    switch (score) {
      case "Champion":
        icon = ChampionScore;
        text = "Champion";
        break;
      case "On the right track":
        icon = RightTrackScore;
        text = "On the right track";
        break;
      case "Starting Point":
        icon = StartingPointScore;
        text = "Starting point";
        break;
      // deprecated scores for improve transition to new scores
      case "Good":
        icon = GoodScore;
        break;
      case "Middle":
        icon = MiddleScore;
        break;
      case "Bad":
        icon = BadScore;
        break;
      default:
        text = "Skipped";
    }

    if (score === "Good" || score === "Middle" || score === "Bad") {
      return <img src={icon} alt="score icon" />;
    } else {
      return (
        <div className="flex gap-x-2 items-center">
          <img
            src={icon}
            width={24}
            height={24}
            alt="score icon"
            hidden={!icon}
          />
          {text}
        </div>
      );
    }
  };

  return scoreList ? (
    <table className="w-full">
      <thead>
        <tr>
          <th className="w-1/2 text-left pl-8 py-4 font-bold">CLUSTER</th>
          <th className="w-1/2 text-left pl-8 py-4 font-bold">SCORE</th>
        </tr>
      </thead>
      <tbody>
        {clusterList.map((key: string) => (
          <tr className="border-t border-black/10" key={key}>
            <td className="w-1/2 text-left pl-8 py-4">
              <span className="capitalize">{key}</span> city
            </td>
            <td className="w-1/2 text-left pl-8 py-4">
              {parseScore(scoreList[key])}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <img
      src={Spinner}
      alt="spinner"
      className="animate-spin w-14 mx-auto mb-4"
    />
  );
};

export default ResultsSection;
