import React, { useState, useEffect } from "react";

import "./App.css";
import Home from "./components/Home";
import Questionnaire from "./components/Questionnaire";
import tractebelLogo from "./assets/tbl-engie.png";
import tractebelLogoGrey from "./assets/tractebel_logo.svg";
import Snackbar from "./components/Snackbar";
import { ISuccess } from "./interface/ISuccess";
import Finish from "./components/Finish";

const App = () => {
  const [success, setSuccess] = useState<null | ISuccess>(null);
  const [isQuestionnaireFinished, setIsQuestionnaireFinished] =
    useState<boolean>(false);
  const [isQuestionnaireOpen, setIsQuestionnaireOpen] =
    useState<boolean>(false);
  const [cityName, setCityName] = useState<string>("");
  const [formValues, setFormValues] = useState<[]>();
  const [clusterList, setClusterList] = useState<any[]>([]);

  useEffect(() => {
    if (isQuestionnaireFinished && isQuestionnaireOpen) {
      setIsQuestionnaireOpen(false);
    }
  }, [isQuestionnaireFinished, isQuestionnaireOpen]);

  return (
    <div className="min-h-screen flex flex-col justify-between">
      {success !== null && (
        <Snackbar success={success} setSuccess={setSuccess} />
      )}
      <div>
        <header className="px-40 pt-4 pb-4 shadow-lg flex justify-between items-center relative">
          <span className="text-blue-600 text-4xl font-bold tracking-tight gradient-color">
            360 City Scan
          </span>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://digital.tractebel-engie.com/"
          >
            <img className="h-8" src={tractebelLogo} alt="Tractebel logo" />
          </a>
        </header>
        {/* IF to show main site with background and questionary */}
        {!isQuestionnaireFinished && (
          <Home
            cityName={cityName}
            setCityName={setCityName}
            setIsQuestionnaireOpen={setIsQuestionnaireOpen}
          />
        )}
        {isQuestionnaireOpen && (
          <Questionnaire
            setClusterList={setClusterList}
            setSuccess={setSuccess}
            setIsQuestionnaireFinished={setIsQuestionnaireFinished}
            setFormValues={setFormValues}
          />
        )}
        {isQuestionnaireFinished && (
          <Finish
            clusterList={clusterList}
            formValues={formValues}
            setSuccess={setSuccess}
            cityName={cityName}
          />
        )}
      </div>
      {/* ELSE Result of Questionary, PDF, Pardot */}
      <footer className="pb-8 pt-10 px-8 bg-light-gray flex flex-row justify-between items-center mt-20">
        <img src={tractebelLogoGrey} alt="" />
        <div className="flex md:gap-8 gap-2 md:flex-row flex-col">
          <a
            className="text-footer-gray text-sm"
            rel="noreferrer"
            target="_blank"
            href="https://digital.tractebel-engie.com/"
          >
            Our digital solutions
          </a>
          <a
            className="text-footer-gray text-sm"
            rel="noreferrer"
            target="_blank"
            href="https://digital.tractebel-engie.com/tractebel-calculators-disclaimer/"
          >
            Disclaimer
          </a>
        </div>
      </footer>
    </div>
  );
};

export default App;
