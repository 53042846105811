import React from "react";
import Spinner from "../assets/spinner.svg";

const LoadingForm = () => (
  <div className="text-center" data-testid="loading-form">
    <img
      src={Spinner}
      alt="spinner"
      className="animate-spin w-14 mx-auto mb-4"
    />
    <h1 className="text-2xl">Your PDF is being generated</h1>
    <p>
      Please wait a moment as we are preparing your custom PDF report.
      <br />
      After a while your report will be send to your email.
    </p>
  </div>
);

export default LoadingForm;
