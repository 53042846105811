const QuestionnaireModal = ({ popUp, setPopUp }: any) => (
  <div className="fixed top-0 left-0 min-h-screen w-full bg-black/30 flex justify-center items-center z-40">
    <div className="relative rounded-2xl max-h-screen bg-white p-6 max-w-2xl overflow-y-auto overflow-y-auto scrollbar">
      <p className="mb-6 text-lg">{popUp.description}</p>
      <div className="flex justify-end gap-4">
        <button
          className="border border-sky-500 text-sky-500 px-6 py-3 rounded-md font-bold"
          type="button"
          onClick={() => {
            setPopUp((currentState: any) => {
              return { ...currentState, active: false };
            });
          }}
        >
          {popUp.closeButton}
        </button>
        {popUp?.submitButton! && (
          <button
            className="border border-sky-500 text-sky-500 px-6 py-3 rounded-md font-bold"
            type="button"
            onClick={popUp.callbackFn}
          >
            {popUp.submitButton}
          </button>
        )}
      </div>
    </div>
  </div>
);

export default QuestionnaireModal;
