import React, { useState, useEffect } from "react";
import Pin from "../assets/icons/location-on-outline.svg";

const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  /**
   * This code is essential for proper Google Map API script working
   */
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const SearchCity = ({
  setCityName,
  addressHelperError,
  setAddressHelperError,
}) => {
  const [address, setAddress] = useState("");

  useEffect(() => {
    let url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_CLOUD_KEY}&libraries=places`;
    let script = document.querySelector(`script[src="${url}"]`);

    if (script === null) {
      loadScript(url, async () => {
        const input = document.getElementById("address_input");
        const options = {
          types: ["(cities)"],
          componentRestrictions: {},
        };
        const searchBox = new window.google.maps.places.SearchBox(
          input,
          options
        );
        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();
          setAddress(places[0].formatted_address);
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCityName(address);
    // eslint-disable-next-line
  }, [address]);

  return (
    <label htmlFor="address_input" className="mt-10">
      <span className="text-sm text-gray-900">City to scan*</span>
      <div className="flex relative">
        <input
          id="address_input"
          type="text"
          className=" border-[1px] border-gray-600 mt-2 rounded-md w-full rounded-md p-4 pl-12 pr-12 text-black border-white border-2 focus:ring-2 focus:ring-blue-allports focus:ring-offset-2 focus-visible:outline-0"
          placeholder="Enter location"
          required
          aria-required
          value={address}
          onFocus={() => {
            setAddressHelperError(false);
          }}
          onChange={(e) => {
            if (e.target.value.length === 0) {
              setAddressHelperError(true);
            } else {
              setAddressHelperError(false);
            }
            setAddress(e.target.value);
          }}
        />
        <img
          src={Pin}
          alt="map pin"
          className="absolute top-[1.5rem] left-[1rem] pointer-events-none"
        />
        {addressHelperError && (
          <span className="absolute top-[4.6rem] px-2 py-1 text-white text-sm flex flex-row bg-red-error rounded-lg">
            City to scan can't be empty.
          </span>
        )}
      </div>
    </label>
  );
};

export default SearchCity;
