import React, { useState } from "react";
import CloseIcon from "../assets/icons/close.svg";
import FormInput from "./FormInput";
import LoadingForm from "./LoadingForm";
import { SubmitHandler, useForm } from "react-hook-form";
import { IFormValues } from "../interface/IFormValues";
import axios from "axios";

const PDFForm = ({ setIsPDFFormOpen, setSuccess, surveyId, cityName }: any) => {
  const { register, handleSubmit } = useForm();
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const submitForm: SubmitHandler<any> = (data: IFormValues) => {
    setIsLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}survey/${surveyId}/pdf`,
        {
          city: cityName,
          ...data,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(() => {
        setIsLoader(false);
        setIsPDFFormOpen(false);
        setSuccess({
          image: "Success",
          backgroundClass: "bg-green-success",
          message: `Thank you for registering! You should receive an email containing a link to your personalized report within a few minutes.`,
        });
      })
      .catch(() => {
        setIsLoader(false);
        setSuccess({
          image: "Danger",
          backgroundClass: "bg-red-danger",
          message: `The form cannot be submitted. Please check all fields or try again later.`,
        });
      });
  };

  return (
    <div className="fixed top-0 left-0 min-h-screen w-full bg-black/30 flex justify-center items-center z-40">
      <div className="relative rounded-2xl max-h-screen bg-white p-6 max-w-xl overflow-y-auto overflow-y-auto scrollbar">
        {isLoader ? (
          <LoadingForm />
        ) : (
          <>
            <div className="flex items-center justify-between mb-6 ">
              <h1
                className="text-2xl mr-4 font-bold"
                data-testid="pdf-form-header"
              >
                Register now for your free 360° CITY SCAN
              </h1>
              <button
                type="button"
                onClick={() => setIsPDFFormOpen(false)}
                className="h-6"
                data-testid="pdf-form-x-button"
              >
                <img src={CloseIcon} width={24} height={24} alt="close" />
              </button>
            </div>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(submitForm)}
            >
              <div className="flex flex-col sm:flex-row sm:space-x-6 w-full">
                <FormInput
                  name="firstName"
                  placeholder="Name*"
                  register={register}
                  required
                />
                <FormInput
                  name="lastName"
                  placeholder="Surname*"
                  register={register}
                  required
                />
              </div>
              <FormInput
                name="email"
                placeholder="E-mail*"
                type="email"
                register={register}
                required
              />
              <FormInput
                name="company"
                placeholder="Company/Organisation*"
                register={register}
                required
              />
              <FormInput
                required
                name="agreeCalculatorDisclaimer"
                placeholder={
                  <>
                    By ticking the box, you confirm that you have read and that
                    you agree to the{" "}
                    <a
                      href="https://digital.tractebel-engie.com/tractebel-calculators-disclaimer/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sky-500 underline"
                    >
                      Calculators Disclaimer
                    </a>
                    .
                  </>
                }
                type="checkbox"
                register={register}
              />
              <FormInput
                required
                name="readPrivacyStatement"
                placeholder={
                  <>
                    By ticking the box, you confirm that you have read the{" "}
                    <a
                      href="https://digital.tractebel-engie.com/tractebel-digital-data-privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sky-500 underline"
                    >
                      Privacy Statement
                    </a>
                    .
                  </>
                }
                type="checkbox"
                register={register}
              />
              <FormInput
                name="allowMarketingCommunications"
                placeholder={
                  <>
                    By ticking the box, you agree to to receive marketing
                    communications, newsletters, webinar invitations and company
                    news. You can unsubscribe or opt-out from specific topics by
                    adapting your{" "}
                    <a
                      href="https://content.media.tractebel.engie.com/l/890233/2021-12-09/74xsz"
                      target="_blank"
                      rel="noreferrer"
                      className="text-sky-500 underline"
                    >
                      Preferences
                    </a>
                    .
                  </>
                }
                type="checkbox"
                register={register}
              />
              <span className="text-left text-gray-500 text-sm">
                * mandatory field
              </span>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setIsPDFFormOpen(false)}
                  type="button"
                  className="border border-sky-500 text-sky-500 px-6 py-3 rounded-md font-bold"
                  data-testid="pdf-form-cancel-button"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="border border-sky-500 text-white rounded-md bg-sky-500 px-6 py-3 font-bold"
                  data-testid="pdf-form-submits-button"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default PDFForm;
